$(window).load(function () {
  // DISABLE SCROLLING
  $("html").css("overflow", "hidden");
  setTimeout(function () {
    $("html").css("overflow", "unset");
    $(".hp-landTextContainer").css("opacity", "1");
  }, 800);

  //SECOND LINE ANIMATION
  $("#ft-0").addClass("revealText");

  let counter = -1;
  let timeElapsed = 0;

  setInterval(function () {
    // increment the elapsed time
    timeElapsed += 3;
    // if the elapsed time is greater than 359 seconds, reset the counter and elapsed time
    if (timeElapsed > 359) {
      // reset the elapsed time
      // reset the counter
      timeElapsed = 0;
      counter = -2;
      // reset the animation by removing all classes and adding the first one
      for (let i = 0; i < 12; i++) {
        let id = "#ft-" + i;
        $(id).removeClass("revealText");
        $(id).addClass("disappearText");
      }

      $("#ft-0").addClass("revealText");
    }
    if (counter < 12) {
      counter++;
    } else {
      counter = 0;
    }

    let currentID = "#ft-" + counter;
    let nextUp = counter == 12 ? "0" : counter + 1;
    let nextID = "#ft-" + nextUp;

    $(currentID).removeClass("revealText");
    $(currentID).addClass("disappearText");

    setTimeout(function () {
      $(nextID).removeClass("disappearText");
      $(nextID).addClass("revealText");
    }, 500);
  }, 3000);

  //INITIAL
  const videoId = "HzjrINKDoho";
  const youtubeVideoSrc = `https://www.youtube.com/embed/${videoId}?enablejsapi=1`;
  $("#keynote-video-embed").attr("src", youtubeVideoSrc);

  const mqTab = window.matchMedia("(min-width: 1024px)");

  // $("#hp-landing-video").addClass("is-playing");
  $(".scrollDownArrow").css("opacity", "1");
  $(".hp-main").css("opacity", "1");
  $(".arrowLines").css("width", "13px");

  setTimeout(function () {
    $(".arrowLines1")
      .css("transform", "rotate(-45deg)")
      .css("margin-left", "-2px");
    $(".arrowLines2")
      .css("transform", "rotate(45deg)")
      .css("margin-right", "-2px");
  }, 600);

  // SCROLL DOWN ARROW HOVER ANIMATION
  var hoverCount = 0;
  $(".scrollDownArrow").hover(function () {
    hoverCount++;
    //even odd hover detect
    var isEven = function (someNumber) {
      return someNumber % 2 === 0 ? true : false;
    };
    // on even hover do this
    if (isEven(hoverCount) === true) {
      // animate back to standard arrow
      $(".arrowLines").css("width", "13px");
      $(".downArrowLine").removeClass("animated");
      $(".arrowLines1")
        .css("transform", "rotate(-45deg)")
        .css("margin-left", "-2px");
      $(".arrowLines2")
        .css("transform", "rotate(45deg)")
        .css("margin-right", "-2px");
    }
    // on odd hover do this
    else if (isEven(hoverCount) === false) {
      // animated scroll down
      $(".arrowLines").css("width", "15px");
      $(".downArrowLine").addClass("animated");
      $(".arrowLines1")
        .css("transform", "rotate(-225deg)")
        .css("margin-left", "-2.5px");
      $(".arrowLines2")
        .css("transform", "rotate(225deg)")
        .css("margin-right", "-2.5px");
    }
  });

  $(".scrollDownArrow").click(function (e) {
    e.preventDefault();
    $("html, body").animate({ scrollTop: $(".hp-landText").height() }, "slow");
  });

  //DEVICE ICONS
  $(".ok-ic").hover(function () {
    let device = $(this).data("device");

    $(this).toggleClass("ic-hover");
  });

  //BRAND WALL
  $(".marquee").marquee({
    //speed in milliseconds of the marquee
    duration: 60000,
    //gap in pixels between the tickers
    gap: 0,
    //time in milliseconds before the marquee will start animating
    delayBeforeStart: 0,
    //'left' or 'right'
    direction: "left",
    //true or false - should the marquee be duplicated to show an effect of continues flow
    duplicated: true,
    //true or false - should the marquee be shown right away
    startVisible: true,
  });

  //Email form on mobile
  $(".joinText").on("click", function (e) {
    const mq3 = window.matchMedia("(max-width: 769px)");

    if (mq3.matches) {
      $(".joinText").css("top", "5%");
      $("#email_signup").css("display", "block");
    }
  });

  // YOUTUBE POPUP
  $(".watch-btn").on("click", function (e) {
    if (mqTab.matches) {
      $(".watch-btn").css("display", "none");
      $(".hp-vid-container").css("display", "block");
      $(".hp-playing-overlay").css("opacity", ".8");
    } else {
      window.location = "https://www.youtube.com/watch?v=HzjrINKDoho";
    }
  });

  $(".video-close").on("click", function (e) {
    $(".watch-btn").css("display", "flex");
    $(".hp-vid-container").css("display", "none");
    $(".hp-playing-overlay").css("opacity", "0");
    $("#keynote-video-embed").each(function () {
      this.contentWindow.postMessage(
        '{"event":"command","func":"stopVideo","args":""}',
        "*"
      );
    });
  });

  window.onscroll = function (e) {
    //BACK TO TOP
    if ($(window).scrollTop() > $(window).height() * 1) {
      $("#backToTop").css("display", "block").css("opacity", "0.5");
    } else {
      $("#backToTop").css("opacity", "0").css("display", "none");
    }
  };

  $("#backToTop").each(function () {
    $(this).click(function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "slow");
    });
  });
});
